import request from 'superagent';

const Api = {
  get: async () => {
    const { hostname } = window.location;

    const response = await request
      .get(`${process.env.REACT_APP_CONFIGURATIONS_BASE_URL}/${hostname}.json`);
    if (response.ok) {
      return response.body;
    }
    throw Error('Load config failed');
  },
}

export const ConfigurationApi = Api;
