import PropTypes from 'prop-types';

export class RouterModel {
  static History = PropTypes.shape({
    push: PropTypes.func,
    location: RouterModel.Location,
  })

  static Location = PropTypes.shape({
    hash: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.string,
  })

  static Match = PropTypes.shape({
    params: RouterModel.Params,
    path: PropTypes.string,
    url: PropTypes.string,
  })

  static Params = PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  })
}
