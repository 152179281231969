import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { LandingPageLayout } from '../../Layout/LandingPageLayout/LandingPageLayout.component';

import { ROUTES } from '../../constants/routes.constants';

const LandingPageComponent = () => (
  <Switch>
    <Route path={ROUTES.ROOT} component={LandingPageLayout} />
  </Switch>
);

export const LandingPage = LandingPageComponent;