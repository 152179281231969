import { ConfigurationActions } from '../actions/configuration.actions';
import { LocalesActions } from '../actions/locales.actions';

const initialState = {
  shouldInitialize: true,
  updating: false,
  activeLocale: 'en',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ConfigurationActions.REQUEST: {
      return {
        ...state,
        shouldInitialize: false,
        updating: true,
      };
    }
    case ConfigurationActions.SUCCESS: {
      const { configuration } = action;
      return {
        ...state,
        updating: false,
        messages: configuration.locales,
      }
    }
    case LocalesActions.SET: {
      const { language } = action;
      return {
        ...state,
        activeLocale: language,
      }
    }
    default:
        return state;
  }
}
export default reducer;

