import { ConfigurationApi } from '../../api/configuration.api';
import { ErrorActions } from './error.actions';

class Actions {
  static PREFIX = '[CONFIG]';
  static REQUEST = `${Actions.PREFIX} request`;
  static SUCCESS = `${Actions.PREFIX} success`;
  
  static request = () => ({
    type: Actions.REQUEST,
  })
  
  static success = configuration => ({
    type: Actions.SUCCESS,
    configuration,
  })

  static get = () => async dispatch => {
    try {
      dispatch(Actions.request());
      const configuration = await ConfigurationApi.get();
      dispatch(Actions.success(configuration));
    } catch (e) {
      console.log('ERROR', e);
      ErrorActions.error(e);
    }
  }
}

export const ConfigurationActions = Actions;
