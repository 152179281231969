//Packages
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import createBrowserHistory from 'history/createBrowserHistory';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';

import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';

import { Store } from './store';

//Containers
import { App } from './App.container';

//Services
import registerServiceWorker from './registerServiceWorker';


//Providers
import { Provider } from 'react-redux';

addLocaleData(en);
addLocaleData(fr);

const history = createBrowserHistory();
const theme = createMuiTheme({
  palette: {
    primary: indigo,
  },
  typography: {
    fontFamily: ['"ProximaNova"', 'sans-serif'],
    fontSize: 22,
    useNextVariants: true
  },
});

ReactDOM.render(
  <Provider store={Store}>
    <Router history={history}>
      <MuiThemeProvider theme={theme}>
        <App />
    </MuiThemeProvider>
    </Router>
  </Provider>
  , document.getElementById('root'));
registerServiceWorker();
