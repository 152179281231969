import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { HeaderComponent } from './Header.component';

const mapStateToProps = state => ({
  config: state.config,
  locale: state.locale,
});

export const Header = compose(
  withRouter,
  connect(mapStateToProps),
)(HeaderComponent);
