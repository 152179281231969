import React from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import './loader.css'
import { FormattedMessage } from 'react-intl';

export const Loader = () => {
  return (
    <div className="loader-wrapper animated fadeIn">
      <CircularProgress color="secondary" size={30} />
      <FormattedMessage id="page.initialization.wait" />
    </div>
  )
}
