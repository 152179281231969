import React, { Component } from 'react';

import { FormattedMessage } from 'react-intl';

import { ConfigurationModel } from '../../../Models/Configuration.models';

import './footer.css';
import { Grid } from '@material-ui/core';

export class FooterComponent extends Component {
  static propTypes = {
    components: ConfigurationModel.Components.isRequired,
  }

  render() {
    const { components } = this.props;

    return (
      <div className="footer animated fadeInUp">
        <Grid container alignItems="center" justify="center">
          <p>
            <FormattedMessage id="footer.message.part"
              values={{
                email: <strong>{components.assets.customerSupport.email}</strong>,
                phone: <strong>{components.assets.customerSupport.phone}</strong>,
              }}
            />
          </p>
        </Grid>
      </div>
    );
  }
}
