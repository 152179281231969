import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { Card } from '../../../components/card';
import { Title } from './styles';

import './Body.css';

export class BodyComponent extends Component {
  renderCards = cardsConfig => cardsConfig.map((card, index) => 
    <Card key={`card-${index}`} card={card} />
  );

  render() {
    const { config, locale } = this.props;
    const cardsConfig = config.components.cards.map(item => ({
      ...item,
      url: item.url[locale.activeLocale],
    }));

    return (
      <div className="container animated fadeIn" >
        <div className="row welcome">
          <div className="col-xs-12 text-center">
            <Title><FormattedMessage id={"title.message.welcome"} /></Title>
            <p className="col-xs-12 col-md-8 col-md-offset-2"><FormattedMessage id={"title.message.instructions"} /></p>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="row">
            {this.renderCards(cardsConfig)}
          </div>
          <hr />
        </div>
      </div >
    );
  }
}
