import { connect } from 'react-redux';

import { BodyComponent } from './Body.component';

const mapStateToProps = state => {
  return {
    config: state.config,
    locale: state.locale
  };
};

export const Body = connect(mapStateToProps)(BodyComponent);
