import styled from 'styled-components';

export const CardContainer = styled.div`
  max-width: 570px;
`;

export const Title = styled.div`
  font-size: ${({ size }) => size || '30px'};
`;

export const CTALabel = styled.div`
  font-size: 2.0rem;
  color: ${({ color }) => color || 'white'};
  font-weight: 600;
`;
