class Actions {
  static PREFIX = '[ERROR]';
  static ERROR = `${Actions.PREFIX} error`;
  
  static error = error => ({
    type: Actions.ERROR,
    error,
  })
}

export const ErrorActions = Actions;
