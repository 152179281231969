
//Packages
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { ConfigurationActions } from './store/actions/configuration.actions';
import { LocalesActions } from './store/actions/locales.actions';

import { AppComponent } from './App.component';

const mapStateToProps = state => ({
  config: state.config,
  locale: state.locale,
  error: state.error,
});

const mapDispatchToProps = dispatch => ({
  getConfig: () => dispatch(ConfigurationActions.get()),
  setActiveLocale: language => dispatch(LocalesActions.set(language)),
});

export const App = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AppComponent);
