import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { Grid } from '@material-ui/core';

import { LOCALES } from '../../../constants/locales.constants';
import { RouterModel } from '../../../Models/Router.models';

import { MenuContainer } from './styles';
import './header.css';

export class HeaderComponent extends Component {
  static propTypes = {
    config: PropTypes.any.isRequired,
    history: RouterModel.History.isRequired,
    locale: PropTypes.any.isRequired,
  }

  state = {
    anchorEl: null,
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleMenuClick = language => () => {
    this.setState({ anchorEl: null });
    this.props.history.push(`${language}`);
  }

  render() {
    const { anchorEl } = this.state;
    const { config, locale } = this.props;
    const { components } = config;

    return (
      <nav className="navbar navbar-light bg-light animated fadeInDown">
        <Grid container justify="space-between" alignItems="center">
        <a href="/" className="navbar-brand">
          <img className="logo" src={components.assets.logo} alt="Janssen Logo" />
          <div className="tagline-wrapper">
            { components.assets.tagLine ? <span className="divider"></span> : null }
            { components.assets.tagLine ? <img className="tagline" src={components.assets.tagLine} alt="Janssen Logo Tagline, Pharmaceutical Companies of Johnson and Johnson" /> 
                                        : null 
            }
          </div>
        </a>
        {/* Menu */}
        <MenuContainer className="menu pull-right">
          <Button
            color="primary"
            aria-owns={anchorEl ? 'simple-menu' : null}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <FormattedMessage id={`header.language.cts.${locale.activeLocale}`} />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            style={{ transformOrigin: 'center bottom' }}
          >
            <MenuItem onClick={this.handleMenuClick(LOCALES.ENGLISH)}><FormattedMessage id="header.language.english" /></MenuItem>
            <MenuItem onClick={this.handleMenuClick(LOCALES.FRENCH)}><FormattedMessage id="header.language.french" /></MenuItem>
          </Menu>
        </MenuContainer>
        </Grid>
      </nav>
    );
  }
}
