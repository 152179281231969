import React from 'react';

import { Header } from './Header';
import { Footer } from './Footer';
import { Body } from './Body'

export const LandingPageLayout = () => (
  <div>
    <Header />
    <Body />
    <Footer />
  </div>
);
