import React, { Component } from 'react';

import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import './card.css';
import { FormattedMessage } from 'react-intl';
import { Title, CTALabel } from './styled';
import { ConfigurationModel } from '../../Models/Configuration.models';

export class CardComponent extends Component {
  static propTypes = {
    card: ConfigurationModel.Card.isRequired,
  }

  handleClick = url => () => {
    window.open(`https://${url}`, '_blank');
  }

  render() {
    const { content, footer, url, backgroundImage } = this.props.card;

    const backgroundStyle = {
      backgroundImage: `url(${backgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'bottom-right'
    };
    const footerStyle = {
      color: `${footer.color}`,
      backgroundColor: `${footer.backgroundColor}`,
    };

    return (
      <div onClick={this.handleClick(url)}>
        <div className="col-md-6">
          <div className="card link reports" style={backgroundStyle}>
            <div className="title">
              <Title size={content.fontSize} ><FormattedMessage id={content.label} /></Title>
            </div>
            <div className="card-footer" style={footerStyle}>
              <div className="row">
                <CTALabel color={footer.color}><FormattedMessage id={footer.label} /></CTALabel>
                <div className="pull-right">
                  <ArrowForwardIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
} 
