
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';

import '../src/assets/css/lists.css';
import '../src/assets/css/layout.css';
import '../src/assets/css/typography.css';
import '../src/assets/css/overrides.css';


import { ROUTES } from './constants/routes.constants';
import { RouterModel } from './Models/Router.models';
import { getLanguage } from './utils/locale.utils';
import { changeFavicon } from './utils/favicon.utils';
import { LOCALES } from './constants/locales.constants';

import { Loader } from '../src/components/loader';
import { LandingPage } from './Pages/LandingPage';

export class AppComponent extends Component {
  static propTypes = {
    getConfig: PropTypes.func.isRequired,
    setActiveLocale: PropTypes.func.isRequired,
    location: RouterModel.Location.isRequired,
    locale: PropTypes.any,
  }

  state = {
    language: undefined,
    shouldInitialize: true,
  }

  static getDerivedStateFromProps(nextProps, state) {
    const { location, locale } = nextProps;
    const param = location.pathname.replace('/', '');
    if (param !== locale.activeLocale) {
      nextProps.setActiveLocale(param);
    }

    if(!param && state.shouldInitialize) {
      const language = getLanguage();
      nextProps.history.push(language);
      return {
        shouldInitialize: false,
      };
    }
    return null;
  }

  componentDidMount() {
    const { config, getConfig } = this.props;
    if (config.shouldInitialize) {
      getConfig();
    }
  }

  getMessages = () => {
    const { locale } = this.props;
    const { messages, activeLocale } = locale;
    const currentMessages = Object.keys(messages).reduce((acc, key) => {
      acc[key] = messages[key][activeLocale];
      return acc;
    }, {});
    return currentMessages;
  }

  renderSpinner = () => {
    const language = getLanguage();
    const messages = Object.keys(LOCALES.TRANSLATIONS).reduce((acc, key) => {
      acc[key] = LOCALES.TRANSLATIONS[key][language];
      return acc;
    }, {});
    return (
      <IntlProvider key={language} locale={language} messages={messages}>
        <Loader />
      </IntlProvider>
    );
  }

  render() {
    const { config, locale } = this.props;
    if (config.shouldInitialize || config.updating) {
      return this.renderSpinner();
    }

    changeFavicon(config.components.assets.favicon);
    document.title = config.components.portal.pageTitle;
    return (
      <IntlProvider key={locale.activeLocale} locale={locale.activeLocale} messages={this.getMessages()}>
        <Switch>
          <Route path={ROUTES.ROOT} component={LandingPage} />
        </Switch>
      </IntlProvider>
    );
  }
}
