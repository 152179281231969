import { ErrorActions } from '../actions/error.actions';

const initialState = {
  error: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action) {
    case ErrorActions.ERROR: {
      const { error } = action;
      return {
        ...state,
        error,
      };
    }
    default:
        return state;
  }
}
export default reducer;

