export const LOCALES = {
  ENGLISH: 'en',
  FRENCH: 'fr',
  TRANSLATIONS: {
    'page.initialization.wait': {
      'en': 'Loading page, please wait',
      'fr': 'Chargement de la page, veuillez patienter'
    }
  }
};
