import { ConfigurationActions } from '../actions/configuration.actions';

const initialState = {
  shouldInitialize: true,
  updating: false,
  configuration: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ConfigurationActions.REQUEST: {
      return {
        ...state,
        shouldInitialize: false,
        updating: true,
      }
    }
    case ConfigurationActions.SUCCESS: {
      const { configuration } = action;
      return {
        ...state,
        updating: false,
        components: configuration.components,
      }
    }
    default:
      return state;
  }
}
export default reducer;

