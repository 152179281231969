import PropTypes from 'prop-types';

const Url = PropTypes.shape({
  [PropTypes.string]: PropTypes.string,
});

const Locale = PropTypes.shape({
  [PropTypes.string]: {
    [PropTypes.string]: PropTypes.string
  }
});

const Assets = PropTypes.shape({
  favicon: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  tagLine: PropTypes.string,
  customerSupport: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
});

const Card = PropTypes.shape({
  content: PropTypes.shape({
    label: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    fontSize: PropTypes.string.isRequired,
  }),
  footer: PropTypes.shape({
    label: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
  }),
  backgroundImage: PropTypes.string,
  url: PropTypes.oneOfType([Url.isRequired, PropTypes.string]).isRequired,
});

const Components = PropTypes.shape({
  portal: PropTypes.shape({ pageTitle: PropTypes.string.isRequired }),
  assets: Assets,
  cards: PropTypes.arrayOf(Card),
});

export class ConfigurationModel {
  static Config = PropTypes.shape({
    locales: Locale.isRequired,
    components: Components.isRequired,
  })

  static Locale = PropTypes.shape({
    [PropTypes.string]: {
      [PropTypes.string]: PropTypes.string
    }
  })

  static Components = Components

  static Assets = Assets

  static Url = Url

  static Card = Card
}