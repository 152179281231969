class Actions {
  static PREFIX = '[LOCALES]';
  static SET = `${Actions.PREFIX} set`;
  
  static set = language => ({
    type: Actions.SET,
    language,
  })
}

export const LocalesActions = Actions;
