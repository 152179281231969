import { LOCALES } from '../constants/locales.constants';

export const getLanguage = () => {
  const { language } = window.navigator;
  if (language.includes(LOCALES.ENGLISH)) {
    return LOCALES.ENGLISH;
  } else if (language.includes(LOCALES.FRENCH)) {
    return LOCALES.FRENCH;
  }
  return LOCALES.ENGLISH;
}