import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import locale from './reducers/locale.reducer';
import config from './reducers/config.reducer';
import error from './reducers/error.reducer';

const rootReducer = combineReducers({
  locale,
  config,
  error,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middleware = [thunk];

export const Store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(...middleware)
));
